import App from '@/App';
import '@/index.css';
import reportWebVitals from '@/reportWebVitals';
import { SentryService } from '@/services/sentry';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

SentryService.init();

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
